//手机号正则校验
export function validPhone(str) {
  let phoneRegex = /^1[3456789]\d{9}$/
  return phoneRegex.test(str.trim())
}

//身份证号码校验
export function validIdCard(str) {
  let idcardRegex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  // let idcardRegex = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return idcardRegex.test(str.trim())
}

//邮箱校验
export function validEmail(str) {
  // let emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  let emailRegex = /^[\da-z]+([\-\.\_]?[\da-z]+)*@[\da-z]+([\-\.]?[\da-z]+)*(\.[a-z]{2,})+$/
  return emailRegex.test(str.trim())
}

//根据身份证号码获取出年龄
export function getAge(identify) {
  var UUserCard = identify;
  if (UUserCard != null && UUserCard != "") {
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
    if (
      UUserCard.substring(10, 12) < month ||
      (UUserCard.substring(10, 12) == month &&
        UUserCard.substring(12, 14) <= day)
    ) {
      age++;
    }
    return age;
  }
}

 //根据身份证号码获取性别
 export function getSex(UUserCard) {
  if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
    return "M"; //男
  } else {
    return "F"; //女
  }
}

//根据身份证获取生日
export function getBirth(idCard) {
      
  var birthday = "";

  if(idCard != null && idCard != ""){
    if(idCard.length == 15){
      birthday = "19"+idCard.slice(6,12);
    } else if(idCard.length == 18){
      birthday = idCard.slice(6,14);
    }   
    return birthday.replace(/(.{4})(.{2})/,"$1-$2-");
    //通过正则表达式来指定输出格式为:1990-01-01
  }
}
//获取当前日期
export function getNowFormatDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
      month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

