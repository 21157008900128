var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insure"},[_c('div',{staticClass:"applicantContent"},[_vm._m(0),_c('div',{staticClass:"form"},[_c('div',{staticClass:"uni-form-item"},[_c('div',{staticClass:"tit"},[_vm._v("姓名")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.holder.commonName),expression:"form.holder.commonName"}],staticClass:"uni-input",attrs:{"placeholder":"点击输入姓名","onkeyup":(_vm.form.holder.commonName = _vm.form.holder.commonName.replace(
              /\s+/g,
              ''
            )),"maxlength":"20"},domProps:{"value":(_vm.form.holder.commonName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.holder, "commonName", $event.target.value)}}})]),_c('div',{staticClass:"uni-form-item"},[_c('div',{staticClass:"tit"},[_vm._v("身份证号")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.holder.certiCode),expression:"form.holder.certiCode"}],staticClass:"uni-input",attrs:{"placeholder":"点击输入身份证号","onkeyup":(_vm.form.holder.certiCode = _vm.form.holder.certiCode.replace(
              /\s+/g,
              ''
            )),"maxlength":"18"},domProps:{"value":(_vm.form.holder.certiCode)},on:{"blur":_vm.changeIdCardtb,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.holder, "certiCode", $event.target.value)}}})]),_c('div',{staticClass:"uni-form-item"},[_c('van-field',{attrs:{"type":"tel","label":"手机号","onkeyup":(_vm.form.holder.extensionInfos.mobileTel =
              _vm.form.holder.extensionInfos.mobileTel.replace(/\s+/g, '')),"maxlength":"11","placeholder":"点击输入手机号"},model:{value:(_vm.form.holder.extensionInfos.mobileTel),callback:function ($$v) {_vm.$set(_vm.form.holder.extensionInfos, "mobileTel", $$v)},expression:"form.holder.extensionInfos.mobileTel"}})],1),_c('div',{staticClass:"uni-form-item"},[_c('van-field',{attrs:{"label":"验证码","placeholder":"点击输入验证码","maxlength":"6"},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}}),(_vm.isCode)?_c('p',{staticClass:"verificationCode",on:{"click":_vm.getCode}},[_vm._v(" 获取验证码 ")]):_c('p',{staticClass:"verificationCodeTime"},[_vm._v(_vm._s(_vm.counts)+" S后获取")])],1)]),_vm._m(1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"uni-form-item"},[_c('div',{staticClass:"tit"},[_vm._v("是投保人的")]),_c('div',{staticClass:"uni-input",on:{"click":_vm.changeRelationship}},[_c('div',{class:_vm.relationshipValue ? 'input' : 'inputno'},[_vm._v(" "+_vm._s(_vm.relationshipValue ? _vm.relationshipValue : "与投保人关系")+" ")])]),_c('CommonPiker',{attrs:{"isShow":_vm.holderRelationship,"isShowStr":"holderRelationship","columns":_vm.relationshipList},on:{"callback":_vm.callbackRelationship}}),_c('img',{staticClass:"arrow",attrs:{"src":require("../../static/arrow-icon.png"),"mode":"widthFix"}})],1),_c('div',{staticClass:"uni-form-item"},[_c('div',{staticClass:"tit"},[_vm._v("姓名")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.insuredsDetail.commonName),expression:"insuredsDetail.commonName"}],staticClass:"uni-input",attrs:{"onkeyup":(_vm.insuredsDetail.commonName = _vm.insuredsDetail.commonName.replace(
              /\s+/g,
              ''
            )),"placeholder":"点击输入姓名","max":"20"},domProps:{"value":(_vm.insuredsDetail.commonName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.insuredsDetail, "commonName", $event.target.value)}}})]),_c('div',{staticClass:"uni-form-item"},[_c('div',{staticClass:"tit"},[_vm._v("身份证号")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.insuredsDetail.certiCode),expression:"insuredsDetail.certiCode"}],staticClass:"uni-input",attrs:{"placeholder":"点击输入身份证号","maxlength":"18","onkeyup":(_vm.insuredsDetail.certiCode = _vm.insuredsDetail.certiCode.replace(
              /\s+/g,
              ''
            ))},domProps:{"value":(_vm.insuredsDetail.certiCode)},on:{"blur":_vm.changeIdCard,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.insuredsDetail, "certiCode", $event.target.value)}}})]),_c('div',{staticClass:"uni-form-item"},[_c('van-field',{attrs:{"onkeyup":(_vm.insuredsDetail.extensionInfos.mobileTel =
              _vm.insuredsDetail.extensionInfos.mobileTel.replace(/\s+/g, '')),"type":"tel","label":"手机号","placeholder":"点击输入手机号","maxlength":"11"},model:{value:(_vm.insuredsDetail.extensionInfos.mobileTel),callback:function ($$v) {_vm.$set(_vm.insuredsDetail.extensionInfos, "mobileTel", $$v)},expression:"insuredsDetail.extensionInfos.mobileTel"}})],1)]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"clauseBox"},[_c('div',{staticClass:"clause"},[_c('van-checkbox',{attrs:{"shape":"square","icon-size":"14px","checked-color":"#FF6C40"},model:{value:(_vm.isRead),callback:function ($$v) {_vm.isRead=$$v},expression:"isRead"}}),_c('div',[_vm._v(" 我已阅读并同意"),_c('span',{on:{"click":function($event){return _vm.customerNotification('xmInsuranceInformation')}}},[_vm._v(" 《投保须知及声明授权》")]),_c('span',{on:{"click":function($event){return _vm.customerNotification('xmInsuranceClause')}}},[_vm._v(" 《信美相互肝脏（A款）特定疾病保险》")]),_vm._v("条款、 "),_c('span',{on:{"click":function($event){return _vm.customerNotification('xmAutomatedTransit')}}},[_vm._v(" 《银行自动转账授权书》")]),_vm._v("、 "),_c('span',{on:{"click":function($event){return _vm.customerNotification('xmServiceDetails')}}},[_vm._v(" 《服务详情》")]),_vm._v("、 "),_c('span',{on:{"click":function($event){return _vm.customerNotification('xmImportantNote')}}},[_vm._v(" 《信美相互肝脏（A款）特定疾病保险》-重要提示")])])],1)]),_c('div',{staticClass:"prcieContent"},[_c('div',{staticClass:"price"},[_vm._v(" 价格：¥ "),_c('div',{staticStyle:{"margin":"0 5px"}},[_vm._v(" "+_vm._s(_vm.price == "--" ? "--" : _vm.price / 100)+" ")]),_c('div',[_vm._v("/年起")])]),_c('div',{staticClass:"btn",on:{"click":_vm.submit}},[_vm._v("立即投保")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applicant-title"},[_c('div',{staticClass:"titleNum"},[_vm._v("1")]),_c('div',{staticClass:"titleName"},[_vm._v("投保人信息")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applicant-title"},[_c('div',{staticClass:"titleNum"},[_vm._v("2")]),_c('div',{staticClass:"titleName"},[_vm._v("被保人信息")])])}]

export { render, staticRenderFns }