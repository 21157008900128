<template>
  <div class="insure">
    <div class="applicantContent">
      <!-- 投保人模块 -->
      <div class="applicant-title">
        <div class="titleNum">1</div>
        <div class="titleName">投保人信息</div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">姓名</div>
          <input
            class="uni-input"
            placeholder="点击输入姓名"
            :onkeyup="
              (form.holder.commonName = form.holder.commonName.replace(
                /\s+/g,
                ''
              ))
            "
            maxlength="20"
            v-model="form.holder.commonName"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">身份证号</div>
          <input
            class="uni-input"
            placeholder="点击输入身份证号"
            v-model="form.holder.certiCode"
            :onkeyup="
              (form.holder.certiCode = form.holder.certiCode.replace(
                /\s+/g,
                ''
              ))
            "
            maxlength="18"
            @blur="changeIdCardtb"
          />
        </div>
        <!-- <div class="uni-form-item">
          <div class="tit">联系地址</div>
          <input
            class="uni-input"
            placeholder="请输入详细地址，具体到门牌号"
            maxlength="50"
            :onkeyup="
              (form.holder.extensionInfos.address4 =
                form.holder.extensionInfos.address4.replace(/\s+/g, ''))
            "
            v-model="form.holder.extensionInfos.address4"
          />
        </div> -->
        <div class="uni-form-item">
          <van-field
            v-model="form.holder.extensionInfos.mobileTel"
            type="tel"
            label="手机号"
            :onkeyup="
              (form.holder.extensionInfos.mobileTel =
                form.holder.extensionInfos.mobileTel.replace(/\s+/g, ''))
            "
            maxlength="11"
            placeholder="点击输入手机号"
          />
        </div>
        <div class="uni-form-item">
          <van-field
            v-model="verificationCode"
            label="验证码"
            placeholder="点击输入验证码"
            maxlength="6"
          />
          <p class="verificationCode" v-if="isCode" @click="getCode">
            获取验证码
          </p>
          <p class="verificationCodeTime" v-else>{{ counts }} S后获取</p>
        </div>
        <!-- <div class="uni-form-item">
          <van-field
            v-model="form.holder.extensionInfos.email"
            label="电子邮箱"
            :onkeyup="
              (form.holder.extensionInfos.email =
                form.holder.extensionInfos.email.replace(/\s+/g, ''))
            "
            placeholder="点击输入电子邮箱"
            maxlength="50"
          />
        </div> -->
      </div>
      <!-- 被保人模块 -->
      <div class="applicant-title">
        <div class="titleNum">2</div>
        <div class="titleName">被保人信息</div>
      </div>
      <div class="form">
        <div class="uni-form-item">
          <div class="tit">是投保人的</div>
          <div class="uni-input" @click="changeRelationship">
            <div :class="relationshipValue ? 'input' : 'inputno'">
              {{ relationshipValue ? relationshipValue : "与投保人关系" }}
            </div>
          </div>
          <CommonPiker
            :isShow="holderRelationship"
            isShowStr="holderRelationship"
            @callback="callbackRelationship"
            :columns="relationshipList"
          />
          <img
            class="arrow"
            src="../../static/arrow-icon.png"
            mode="widthFix"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">姓名</div>
          <input
            class="uni-input"
            :onkeyup="
              (insuredsDetail.commonName = insuredsDetail.commonName.replace(
                /\s+/g,
                ''
              ))
            "
            placeholder="点击输入姓名"
            max="20"
            v-model="insuredsDetail.commonName"
          />
        </div>
        <div class="uni-form-item">
          <div class="tit">身份证号</div>
          <input
            class="uni-input"
            placeholder="点击输入身份证号"
            maxlength="18"
            :onkeyup="
              (insuredsDetail.certiCode = insuredsDetail.certiCode.replace(
                /\s+/g,
                ''
              ))
            "
            v-model="insuredsDetail.certiCode"
            @blur="changeIdCard"
          />
        </div>
        <!-- <div class="uni-form-item">
          <div class="tit">联系地址</div>
          <input
            class="uni-input"
            :onkeyup="
              (insuredsDetail.extensionInfos.address4 =
                insuredsDetail.extensionInfos.address4.replace(/\s+/g, ''))
            "
            placeholder="请输入详细地址，具体到门牌号"
            v-model="insuredsDetail.extensionInfos.address4"
            maxlength="50"
          />
        </div> -->
        <div class="uni-form-item">
          <van-field
            v-model="insuredsDetail.extensionInfos.mobileTel"
            :onkeyup="
              (insuredsDetail.extensionInfos.mobileTel =
                insuredsDetail.extensionInfos.mobileTel.replace(/\s+/g, ''))
            "
            type="tel"
            label="手机号"
            placeholder="点击输入手机号"
            maxlength="11"
          />
        </div>

        <!-- <div class="uni-form-item">
          <van-field
            v-model="insuredsDetail.extensionInfos.email"
            :onkeyup="
              (insuredsDetail.extensionInfos.email =
                insuredsDetail.extensionInfos.email.replace(/\s+/g, ''))
            "
            label="电子邮箱"
            maxlength="50"
            placeholder="点击输入电子邮箱"
          />
        </div> -->
      </div>
      <!-- 提交模块 -->
      <!-- <SubmitTip ref="submitTip" @submit="apply"  :premium="productDetail.premium" /> -->
      <div class="footer">
        <div class="clauseBox">
          <div class="clause">
            <van-checkbox
              v-model="isRead"
              shape="square"
              icon-size="14px"
              checked-color="#FF6C40"
            >
            </van-checkbox>
            <div>
              我已阅读并同意<span
                @click="customerNotification('xmInsuranceInformation')"
              >
                《投保须知及声明授权》</span
              >
              <span @click="customerNotification('xmInsuranceClause')">
                《信美相互肝脏（A款）特定疾病保险》</span
              >条款、
              <span @click="customerNotification('xmAutomatedTransit')">
                《银行自动转账授权书》</span
              >、
              <span @click="customerNotification('xmServiceDetails')">
                《服务详情》</span
              >、
              <span @click="customerNotification('xmImportantNote')">
                《信美相互肝脏（A款）特定疾病保险》-重要提示</span
              >
            </div>
          </div>
        </div>
        <div class="prcieContent">
          <div class="price">
            价格：¥
            <div style="margin: 0 5px">
              {{ price == "--" ? "--" : price / 100 }}
            </div>
            <div>/年起</div>
          </div>
          <div class="btn" @click="submit">立即投保</div>
        </div>
      </div>
      <!--弹框提示  -->
      <!--弹出框，赋予chargeBtn事件-->
      <!-- <van-dialog
        v-model="interMoney"
        title="请输入保费"
        show-cancel-button
        :beforeClose="interMoneyCallback"
      >
        <van-field
          v-model="backMoney"
          rows="1"
          autosize
          label="保费"
          type="number"
          placeholder="请输入保费"
        />
      </van-dialog> -->
    </div>
  </div>
</template>
<script>
import CommonPiker from "../../components/commonPiker.vue";
import {
  xmReckon,
  xmInsured,
  payOrderWxPub,
  esignComparison,
  getCode,
  checkCode,
} from "../../../../utils/api";
import {
  getAge,
  validIdCard,
  getSex,
  getBirth,
  validPhone,
  validEmail,
  getNowFormatDate,
} from "../../../../utils/validate";
export default {
  components: {
    CommonPiker,
  },
  data() {
    return {
      isRead: false, //是否阅读资料
      price: "--", //价格
      holderRelationship: false, //是否展示上拉弹窗
      relationshipList: [
        //关系列表
        {
          text: "本人",
          id: 1,
        },
        {
          text: "子女",
          id: 2,
        },
        {
          text: "配偶",
          id: 3,
        },
        {
          text: "父母",
          id: 10,
        },
      ],
      relationshipValue: "", //
      form: {
        productId: this.$route.query.id, //产品id
        openId: sessionStorage.getItem("openid"), //openid
        holder: {
          //投保人信息
          commonName: "", //投保人姓名
          certiCode: "", //证件号码
          birthdate: "", //生日
          gender: "", //性别
          holderId: "", //被保人是本人1、不是本人2
          extensionInfos: {
            // address4: "", //联系地址
            mobileTel: "", //手机号码
            // email: "", //邮箱
          },
        },
        insureds: [], //被保人信息
        coverages: [], //险种信息
      },
      insuredsDetail: {
        //被保人信息
        sameWithHolder: "", //是否同投保人
        birthdate: "", //生日
        commonName: "", //姓名
        certiCode: "", //证件号
        relationToPH: "", //与投保人关系
        gender: "", //性别
        extensionInfos: {
          // email: "",
          mobileTel: "",
          // address4: "",
        },
      },
      isFirstEnter: false,
      isApplicant: false, //投保人姓名和身份证是否匹配
      isRecognizee: false, //被保人姓名和身份证是否匹配
      verificationCode: "", //验证码
      isCode: true, //是否发送验证码
      timer: null,
      counts: 30,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "pdf") {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    document.title = "填写资料";
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      this.isFirstEnter = false;
      this.isRead = false; //是否阅读资料
      this.price = "--"; //价格
      this.holderRelationship = false; //是否展示上拉弹窗
      this.relationshipValue = ""; //
      this.form = {
        productId: this.$route.query.id, //产品id
        openId: sessionStorage.getItem("openid"), //openid
        holder: {
          //投保人信息
          commonName: "", //投保人姓名
          certiCode: "", //证件号码
          birthdate: "", //生日
          gender: "", //性别
          extensionInfos: {
            // address4: "", //联系地址
            mobileTel: "", //手机号码
            // email: "", //邮箱
          },
        },
        insureds: [], //被保人信息
        coverages: [], //险种信息
      };
      this.insuredsDetail = {
        //被保人信息
        sameWithHolder: "", //是否同投保人
        birthdate: "", //生日
        commonName: "", //姓名
        certiCode: "", //证件号
        relationToPH: "", //与投保人关系
        gender: "", //性别
        extensionInfos: {
          // email: "",
          mobileTel: "",
          // address4: "",
        },
      };
      this.isApplicant = false; //投保人姓名和身份证是否匹配
      this.isRecognizee = false; //被保人姓名和身份证是否匹配
      this.verificationCode = ""; //验证码
      this.isCode = true; //是否发送验证码
      this.timer = null;
      this.counts = 30;
    }
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  mounted() {},
  methods: {
    //获取验证码
    getCode() {
      if (this.form.holder.extensionInfos.mobileTel == "") {
        this.$toast("请输入投保人手机号码");
      } else if (!validPhone(this.form.holder.extensionInfos.mobileTel)) {
        this.$toast("投保人手机号码不正确");
      } else {
        let params = {
          phone: this.form.holder.extensionInfos.mobileTel,
        };
        getCode(params).then((res) => {
          console.log(res);
          this.isCode = false;
          let TIME_COUNT = 30;
          if (!this.timer) {
            this.counts = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.counts > 0 && this.counts <= TIME_COUNT) {
                this.counts--;
              } else {
                clearInterval(this.timer);
                this.timer = null;
                this.isCode = true;
              }
            }, 1000);
          }
        });
      }
    },
    //跳转收银台
    payOrderWxPub(detail) {
      let params = {
        openId: this.form.openId,
        channelId: "wx20508d66c240b36a",
        orderNo: detail.orderNo,
      };
      payOrderWxPub(params).then((res) => {
        this.$toast.clear();
        window.location.href = res.result;
      });
    },
    //立即投保
    async submit() {
      this.form.insureds = [];
      this.form.insureds.push(this.insuredsDetail);
      if (this.form.holder.commonName == "") {
        this.$toast("请填写投保人姓名");
      } else if (this.form.holder.certiCode == "") {
        this.$toast("请填写投保人身份证号");
      } else if (!validIdCard(this.form.holder.certiCode)) {
        this.$toast("投保人身份证号不正确");
      }
      // else if (this.form.holder.extensionInfos.address4 == "") {
      //   this.$toast("请填写投保人联系地址");
      // }
      // else if (
      //   this.form.holder.extensionInfos.address4.match(/[\u4E00-\u9FA5]/g) ==
      //     null ||
      //   this.form.holder.extensionInfos.address4.match(/[\u4E00-\u9FA5]/g)
      //     .length < 3
      // ) {
      //   this.$toast("投保人联系地址不得低于三个汉字");
      // }
      else if (this.form.holder.extensionInfos.mobileTel == "") {
        this.$toast("请填写投保人手机号");
      } else if (!validPhone(this.form.holder.extensionInfos.mobileTel)) {
        this.$toast("投保人手机号不正确");
      } else if (this.verificationCode == "") {
        this.$toast("请填写投保人手机号验证码");
      }
      // else if (this.form.holder.extensionInfos.email == "") {
      //   this.$toast("请填写投保人邮箱");
      // } else if (!validEmail(this.form.holder.extensionInfos.email)) {
      //   this.$toast("投保人邮箱不正确");
      // }
      else if (this.insuredsDetail.relationToPH == "") {
        this.$toast("请选择与投保人关系");
      } else if (this.insuredsDetail.commonName == "") {
        this.$toast("请填写被保人姓名");
      } else if (this.insuredsDetail.certiCode == "") {
        this.$toast("请填写被保人身份证号");
      } else if (!validIdCard(this.insuredsDetail.certiCode)) {
        this.$toast("被保人身份证号不正确");
      }
      // else if (this.insuredsDetail.extensionInfos.address4 == "") {
      //   this.$toast("请填写被保人联系地址");
      // }
      // else if (
      //   this.insuredsDetail.extensionInfos.address4.match(/[\u4E00-\u9FA5]/g) ==
      //     null ||
      //   this.insuredsDetail.extensionInfos.address4.match(/[\u4E00-\u9FA5]/g)
      //     .length < 3
      // ) {
      //   this.$toast("投保人联系地址不得低于三个汉字");
      // }
      else if (this.insuredsDetail.extensionInfos.mobileTel == "") {
        this.$toast("请填写被保人手机号");
      } else if (!validPhone(this.insuredsDetail.extensionInfos.mobileTel)) {
        this.$toast("被保人手机号不正确");
      }
      // else if (this.insuredsDetail.extensionInfos.email == "") {
      //   this.$toast("请填写被保人邮箱");
      // } else if (!validEmail(this.insuredsDetail.extensionInfos.email)) {
      //   this.$toast("被保人邮箱不正确");
      // }
      else if (!this.isRead) {
        this.$toast("请先阅读相关资料");
      }
      // else if (this.price == "--") {
      //   this.$toast("请填写大于18周岁小于65周岁的被保人");
      // }
      // else if(!this.isApplicant){
      //   this.$toast("投保人姓名和身份证号不匹配");
      // }
      // else if(!this.isRecognizee){
      //   this.$toast("被保人姓名和身份证号不匹配");
      // }
      else {
        this.isRecognizee = false;
        this.isApplicant = false;
        this.$toast.loading({
          message: "投保中...",
          forbidClick: true,
          duration: 0,
        });
        let params1 = {
          name: this.form.holder.commonName,
          idNo: this.form.holder.certiCode,
        };
        await this.esignComparison(params1, "Applicant"); //投保人姓名和身份证校验
        let params2 = {
          name: this.insuredsDetail.commonName,
          idNo: this.insuredsDetail.certiCode,
        };
        await this.esignComparison(params2, "Recognizee"); //被保人姓名和身份证校验
        let paramsPhone = {
          code: this.verificationCode,
        };
        if (this.isApplicant && this.isRecognizee) {
          await checkCode(
            this.form.holder.extensionInfos.mobileTel,
            paramsPhone
          ).then((res) => {
            console.log(res);
            if (res == undefined) {
            } else {
              let proposals = [];
              proposals.push(this.form);
              let params = {
                proposals: proposals,
              };

              xmInsured(params).then((res1) => {
                this.payOrderWxPub(res1.result);
                let params1 = {
                  feeAmount: res1.result.installPrem,
                  orderNo: res1.result.orderNo,
                  proposalNumber: res1.result.proposalNumber,
                };
                localStorage.setItem("xmIssueParams", JSON.stringify(params1));
              });
            }
          });
        }
      }
    },
    //校验姓名和身份证是否匹配
    async esignComparison(params, type) {
      await esignComparison(params).then((res) => {
        if (res.result != null && type == "Applicant") {
          this.isApplicant = true;
        }
        if (res.result != null && type == "Recognizee") {
          this.isRecognizee = true;
        }
      });
    },
    //投保人证件号失焦事件
    async changeIdCardtb() {
      if (validIdCard(this.form.holder.certiCode)) {
        this.form.holder.gender = getSex(this.form.holder.certiCode);
        this.form.holder.birthdate = getBirth(this.form.holder.certiCode);
      } else {
        // this.price = "--";
      }
    },
    //被保人证件号失焦事件
    async changeIdCard() {
      if (validIdCard(this.insuredsDetail.certiCode)) {
        let age = getAge(this.insuredsDetail.certiCode);
        this.insuredsDetail.gender = getSex(this.insuredsDetail.certiCode);
        this.insuredsDetail.birthdate = getBirth(this.insuredsDetail.certiCode);
        let today = getNowFormatDate().split("-");
        let monthB = getBirth(this.insuredsDetail.certiCode).split("-");
        if (
          today[1] == monthB[1] &&
          today[2] == monthB[2] &&
          getAge(this.insuredsDetail.certiCode) == "66"
        ) {
          age = "65";
        }
        this.xmReckon(age);
      } else {
        this.price = "--";
      }
    },
    changeRelationship() {
      this.holderRelationship = true;
    },

    //选择与投保人关系的回调
    callbackRelationship(value) {
      this.relationshipValue = value.text;

      if (value.id == 1) {
        this.form.holder.holderId = 1;

        this.insuredsDetail = {
          //被保人信息
          sameWithHolder: "1",
          birthdate: this.form.holder.birthdate, //生日
          commonName: this.form.holder.commonName, //姓名
          certiCode: this.form.holder.certiCode, //证件号
          gender: this.form.holder.gender, //性别
          extensionInfos: {
            // email: this.form.holder.extensionInfos.email,
            mobileTel: this.form.holder.extensionInfos.mobileTel,
            // address4: this.form.holder.extensionInfos.address4,
          },
        };
        if (this.insuredsDetail.certiCode != "") {
          let age = getAge(this.insuredsDetail.certiCode);
          this.xmReckon(age);
        }
      } else {
        this.form.holder.holderId = 2;

        this.insuredsDetail = {
          //被保人信息
          sameWithHolder: "0",
          birthdate: "", //生日
          commonName: "", //姓名
          certiCode: "", //证件号
          gender: "", //性别
          extensionInfos: {
            // email: "",
            mobileTel: "",
            // address4: "",
          },
        };
      }
      this.insuredsDetail.relationToPH = value.id;
    },
    //查看资料
    customerNotification(type) {
      this.$router.push({
        path: "/pdf",
        query: {
          type: type,
        },
      });
    },
    //试算
    xmReckon(age) {
      let params = {
        age: age,
        planId: this.$route.query.planId,
        schemeId: this.$route.query.schemeId,
      };
      this.$toast.loading({
        message: "价格计算中...",
        forbidClick: true,
        duration: 0,
      });
      xmReckon(params).then((res) => {
        if (res) {
          console.log(res, "resresres");
          this.$toast.clear();
          let price = 0,
            arr = [];
          res.result.map((item, index) => {
            let obj = {
              coverageId: index + 1,
              productName: item.dutyName,
              productCode: item.dutyCode,
              amount: item.basicAmount,
              totalPremAf: item.reckonPrice,
              coverageInsuredList: [
                {
                  orderId: index + 1,
                },
              ],
            };
            arr.push(obj);
            price += parseFloat(item.reckonPrice);
          });
          this.price = price;
          this.form.coverages = arr;
        } else {
          this.price = "--";
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.verificationCode {
  width: 100px;
  height: 30px;
  background: $primary-color;
  border-radius: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verificationCodeTime {
  width: 100px;
  height: 30px;
  background: #e5e5e5;
  border-radius: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
input:disabled {
  background-color: #fff; //修改默认灰色样式
  color: #000;
  opacity: 1; //默认的不透明级别为0.3
  -webkit-text-fill-color: #000; //字体颜色安卓与IOS适配
  -webkit-opacity: 1; ///不透明级别安卓与IOS适配
}
// input{
//   color: #000 !important;
// }

.insure {
  padding: 10px;
  /deep/ .van-dialog__confirm {
    .van-button__text {
      color: #ff6c40;
    }
  }
  /deep/ .van-cell {
    padding: 5px 0;
  }
  /deep/ .van-cell__title {
    width: 90px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 44px;
    margin-right: 0;
  }

  /deep/ .van-cell::after {
    border-bottom: 0;
  }

  /deep/ .van-cell__value {
    width: 100%;
    flex: 1;
    margin-left: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    .van-field__body {
      height: 100%;
    }
    input::-webkit-input-placeholder {
      color: #999;
    }
  }
  /deep/ .van-dialog {
    .van-cell {
      padding: 20px;
    }
  }
  .applicantContent {
    padding-bottom: 150px;
    .applicant-title {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .titleNum {
        width: 18px;
        height: 18px;
        background: #ff6c40;
        border-radius: 2px;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 11px;
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .titleName {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-left: 5px;
      }
    }
    .form {
      width: 100%;
      margin-top: 5px;
      .uni-form-item {
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        .btnCode {
          width: 114px;
          height: 30px;
          background: #ff6c40;
          border-radius: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
        }
        .tit {
          width: 90px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 44px;
        }
        .uni-input {
          width: 100%;
          flex: 1;
          margin-left: 16px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          padding: 0;
          .input {
            color: #000;
          }
          .inputno {
            color: #999;
          }
        }
        .arrow {
          width: 10px;
          font-size: 16px;
          margin-right: 17px;
        }
      }
    }
  }
}
.beneficiaryType {
  display: flex;
  margin-top: 34px;

  text {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 72px;
    border-radius: 42px;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;

    margin-right: 40px;
  }

  .beneficiary-active {
    background: linear-gradient(90deg, #ffa466 0%, #ff6c40 100%);
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid #ff6c40;
    color: #ff6c40;
    box-sizing: border-box;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .beneficiaryTit {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
    .line1 {
      float: left;
    }
    .line2 {
      float: left;
    }
  }
  .delete {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #999999;
    line-height: 5px;
  }
}
.add {
  width: 124px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #ff6c40;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #ff6c40;
  font-size: 14px;
  font-weight: 500;
  color: #ff6c40;
  line-height: 36px;
  margin: 0 auto;
  margin-top: 20px;
}
.footer {
  // width: 100%;
  // height: 80px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // z-index: 100;
  // background-color: #ffffff;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding-bottom: 10px;
  .clauseBox {
    padding: 10px 10px;
    /deep/ .van-checkbox__icon {
      margin-top: 3px;
    }
    .clause {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      div {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding: 0 16px 0 0;
        font-weight: 400;
        color: #333333;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        font-weight: 400;
        color: #ff6c40;
      }
    }
  }

  .prcieContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .price {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 500;
      color: #ff6c40;
      line-height: 41px;
      padding-left: 10px;
      text {
        font-size: 40px;
      }
    }
    .btn {
      margin-right: 10px;
      width: 116px;
      height: 45px;
      line-height: 45px;
      background: linear-gradient(90deg, #ffa466 0%, #ff6c40 100%);
      border-radius: 48px;
      color: #ffffff;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.beneficiaryType {
  display: flex;
  margin-top: 34px;
  div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 40px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    margin-right: 10px;
  }

  .beneficiary-active {
    background: linear-gradient(90deg, #ffa466 0%, #ff6c40 100%);
    color: #ffffff;
  }

  .beneficiary-no-active {
    border: 1px solid #ff6c40;
    color: #ff6c40;
    box-sizing: border-box;
  }
}

.btns {
  display: flex;
  align-items: center;
  margin-top: 15px;

  div {
    height: 40px;
    padding: 5px 5px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
  }

  .active {
    background: linear-gradient(90deg, #ffa466 0%, #ff6c40 100%);
    color: #ffffff;
    padding: 0 25px;
  }

  .no-active {
    padding: 0 25px;
    border: 2px solid #ff6c40;
    box-sizing: border-box;
    color: #ff6c40;
  }
}
</style>
